import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppsFlyerActions from "../../actions/AppsFlyerActions";
import AppsFlyerCombinedActions from "../../actions/AppsFlyerCombinedActions";
import { useNavigate } from "react-router-dom";

import AppBarActions from "../../actions/AppBarActions";
import Moment from "moment";
// import MomentTZ from 'moment-timezone';
import { DataTableV2 as DataTable } from "../../components/DataTable";
// import ActionButtons from "../../components/ActionButtons";
// import FiltersV2 from "../../components/Filters2/Filters";
import { Text, Box, CheckBox, Tip } from "grommet";

import _ from "lodash";
import Loader from "../../components/Loader";
import Numeral from "numeral";
import Cell from "../../components/Cell";
// import SpeedDial from "../../components/SpeedDial/SpeedDial";
import { Search } from "grommet-icons";
import AppsflyerEntityLabel from "../../components/AppsflyerEntityLabel";
import {
  TextInput,
  Select,
  Button,
  Layer,
  Stack,
  List,
  Heading,
  RadioButtonGroup,
} from "grommet";
import { Spinning } from "grommet-controls";

import {
  Alert,
  Add,
  Edit,
  Duplicate,
  StatusInfo,
  StatusUnknown,
  StatusWarning,
  StatusGoodSmall,
} from "grommet-icons";
import ReadField from "../../components/ReadField";

import {
  // ADN_OPTS,
  // ADN_ID_MAP,
  CONFIG_TYPE_OPTS,
} from "./AppsFlyerCombinedConstants";

// Layer-DuplicateBundleConfig
import DupeBundleConfigLayer from "./components/LayerDuplicateBundleConfig";

const defaultRenderer =
  (propName, type = "", color = "") =>
    (props) => {
      let value = props[propName.toLowerCase()];
      if (type === "numeral") {
        value = Numeral(value).format("0,0");
      } else if (type === "currency") {
        value = Numeral(value).format("0,0$");
      } else if (type === "date") {
        value = Moment(value).format("DD/MM/YY HH:mm");
      }

      return (
        <Cell
          start={"true"}
          flex
          color={color}
          type={type}
        // background={'background'}
        >
          <Tip
            plain
            content={
              <Box border round='small' background={"#E6E6E6"} pad='xsmall'>
                <Text size='xsmall'>
                  {propName} - {value}
                </Text>
              </Box>
            }
          >
            <Box>{value}</Box>
          </Tip>
        </Cell>
      );
    };

const InAppEventsComponent = (props) => {
  // console.log(props);
  const { app_bundle, in_app_events_num, in_app_events_json, bundleEvents } =
    props;

  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = () => setShowModal((s) => !s);

  // console.info(in_app_events_json)
  return (
    <Box justify='center' style={{ maxWidth: "20px" }}>
      {!showModal || !in_app_events_num ? null : (
        <Layer onEsc={toggleModal} onClickOutside={toggleModal}>
          <Box pad='medium'>
            <Heading
              level={3}
            >{`${app_bundle} events (${in_app_events_json.length})`}</Heading>
            <List
              style={{
                maxHeight: 500,
                overflow: "scroll",
              }}
              data={in_app_events_json}
              primaryKey={(item, index) => {

                

 return (
  item ?  <Text size='small' weight='bold'>
                    {`${index + 1}  - ${item.key ? item.key : item}`}
                  </Text> : null
                ) ;
              }}
            // secondaryKey={item => (
            //   <Text size="small" color="dark-4">
            //     {item.af_key}
            //   </Text>
            // )}
            />
          </Box>
        </Layer>
      )}
      <Stack anchor='top-right' onClick={toggleModal}>
        <StatusGoodSmall
          style={{ paddingTop: in_app_events_num ? "0" : "4px" }}
          color={`status-${
            // if there is events
            in_app_events_num ||
              // or default events set "success color"
              bundleEvents
              ? "ok"
              : // else set "warning color"
              "warning"
            }`}
        />
        {in_app_events_num && (
          <Box background='white' pad={{ horizontal: "3px" }} round>
            <Text size='10px'>{in_app_events_num}</Text>
          </Box>
        )}
      </Stack>
      {/* <StatusGoodSmall color={`status-${first_setup ? "ok" : "warning"}`} /> */}
    </Box>
  );
};

const SetupEventsComponents = (props) => {
  const {
    // isAdn,
    bundleEvents,
    bundleEventsNum,
    app_bundle,
  } = props;

  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = () => setShowModal((s) => !s);

  return (
    <Box justify='center' style={{ maxWidth: "20px" }}>
      {!showModal || !bundleEventsNum ? null : (
        <Layer onEsc={toggleModal} onClickOutside={toggleModal}>
          <Box pad='medium'>
            <Heading
              level={3}
            >{`${app_bundle} events (${bundleEventsNum})`}</Heading>
            <List
              style={{
                maxHeight: 500,
                overflow: "scroll",
              }}
              data={bundleEvents}
              primaryKey={(item, index) => {
                return (
                  <Text size='small' weight='bold'>
                    {`${index + 1}  - ${item.event_name
                      } - ${item.event_type.toUpperCase()} <-> ${item.event_key}`}
                  </Text>
                );
              }}
            />
          </Box>
        </Layer>
      )}

      <Text onClick={toggleModal} size='16px'>
        {bundleEventsNum}
      </Text>
    </Box>
  );
};

const bundleTypeLabels = ["Agency", "AdNetwork"];

const AddBundleLayer = (props) => {
  const {
    agencies,
    adnNetworks,
    createAppBundle,
    appBundleLayerData,
    setAppBundleLayerData,

  } = props;


  const {
    isEdit,
    isAdn: isAdnProp,
    app_bundle: editAppBundle,
    app_name: editAppName,
    adn_id: currentAdnId,
    agency_id: currentAgencyId
  } = appBundleLayerData || {};
  const [isAdn, setIsAdn] = React.useState(isAdnProp);
  const [adnId, setAdnId] = React.useState(currentAdnId || 0);
  const [agencyId, setAgencyId] = React.useState(currentAgencyId || 0);

  const [processing, setProcessing] = React.useState(false);
  const [bundleType, setBundleType] = React.useState(
    bundleTypeLabels[Number(!!(isAdn || isAdnProp))]
  );

  const [state_app_bundle, setAppBundle] = React.useState(editAppBundle || "");
  const [state_app_name, setAppName] = React.useState(editAppName || "");

  React.useEffect(() => {
    if (isEdit) {
      setBundleType(bundleTypeLabels[Number(!!isAdnProp || !!isAdn)]);
      setAdnId(currentAdnId)
      setAgencyId(currentAgencyId)
      setIsAdn(isAdnProp || isAdn)
    }
    setAppName(editAppName);

    // if (isAdn !== isAdnProp) {
    //   console.info({isAdn, isAdnProp})
    //   setIsAdn(isAdn)
    // }
  }, [isAdnProp, isAdn, bundleType, editAppName, isEdit, currentAdnId, currentAgencyId]);

  const app_bundle = state_app_bundle || editAppBundle;
  const app_name = state_app_name || editAppName;

  // console.info([isAdnProp, bundleTypeLabels, bundleType, editAppName, isEdit])
  // console.info({isEdit, app_bundle, ...appBundleLayerData, bundleType, bundleTypeLabels})
  if (!appBundleLayerData) {
    return null;
  }
  const title = `${isEdit ? "Edit" : "Add"} App Bundle`;
  const actionText = isEdit ? "Updating App Bundle" : "Adding a new App Bundle";
  const adnProps = !isAdnProp
    ? {}
    : {
      adn_id: currentAdnId,
      adn_name: adnNetworks.ID_MAP && adnNetworks.ID_MAP[currentAdnId].name,
    };
  const buttonProps = {
    label: !isEdit ? "Create" : "Update",
    app_bundle,
    app_name,
    ...adnProps,
    action: isEdit ? "update-name" : "create",
  };

  // console.info({appBundleLayerData, agencies });
  return (
    <Layer>
      <Box pad='medium' size='large'>
        <Heading level='3' margin={{ vertical: "small" }}>
          {title}
        </Heading>
        <Box pad='medium'>
          <ReadField name='App Bundle Type' value={isEdit ? bundleType : null}>
            <RadioButtonGroup
              name='Bundle Type'
              options={["Agency", "Adnetwork"]}
              value={bundleType}
              onChange={(event) => {
                setIsAdn(event.target.value === "Adnetwork" ? true : false);
                setBundleType(event.target.value);
              }}
            />
          </ReadField>
          {isAdnProp || isAdn ? (
            <ReadField
              name='AdNetwork ID'
              value={isEdit ? adnNetworks.ID_MAP[currentAdnId].name : null}
            >
              <Select
                name='Adn ID'
                options={adnNetworks.OPTS}
                labelKey='label'
                valueKey={{ key: "adnId", reduce: true }}
                value={adnId}
                onChange={({ value: nextAdnId }) => {
                  setAdnId(nextAdnId);
                }}
              />
            </ReadField>
          ) : <ReadField
            name='Agency ID'
            value={isEdit ? agencies.ID_MAP[currentAgencyId].name : null}
          >
            <Select
              name='Adn ID'
              options={agencies.OPTS}
              labelKey='label'
              valueKey={{ key: "agencyId", reduce: true }}
              value={agencyId}
              onChange={({ value: nextAgencyId }) => {
                setAgencyId(nextAgencyId);
              }}
            />
          </ReadField>}

          <ReadField name='App Bundle ID' value={isEdit ? app_bundle : null}>
            <TextInput
              placeholder='i.e. id346080608'
              value={app_bundle}
              onChange={({ target }) => {
                setAppBundle(target.value);
              }}
            />
          </ReadField>
          <ReadField name='App Bundle Name'>
            <TextInput
              placeholder='i.e. Fiver '
              value={app_name}
              onChange={({ target }) => {
                setAppName(target.value);
              }}
            />
          </ReadField>
        </Box>
        <Box align='center' flex>
          <Loader text={actionText} processing={processing}>
            <Box direction='row' flex pad='small' fill>
              <Box align='start' flex>
                <Button
                  label='Cancel'
                  onClick={() => setAppBundleLayerData(false)}
                />
              </Box>
              <Box align='end'>
                <Button
                  primary
                  label={buttonProps.label}
                  disabled={!(app_name && app_bundle)}
                  onClick={async () => {
                    const { label, ...appProps } = buttonProps;

                    const actionProps = !(isAdnProp || isAdn)
                      ? {
                        ...appProps,
                        agency_id: agencyId
                      }
                      : {
                        ...appProps,
                        adn_id: adnId,
                      };

                    setProcessing(true);
                    // console.info({ actionProps, isAdn: isAdnProp || isAdn });
                    await createAppBundle(actionProps, false, isAdnProp || isAdn);
                    setProcessing(false);
                    setAppBundleLayerData(false)

                  }}
                />
              </Box>
            </Box>
          </Loader>
        </Box>
      </Box>
    </Layer>
  );
};

const getIcon = (status, size = "large") => {
  switch (status) {
    case "warning":
      return <StatusWarning color='status-warning' size={size} />;
    case "info":
      return <StatusInfo color='status-info' size={size} />;
    default:
      return <StatusUnknown color='status-unknown' size={size} />;
  }
};
const ConfirmLayer = (props) => {
  // console.info({ props }, "confirm");
  const [processing, setProcessing] = React.useState(false);

  const { title, message, onConfirm, onCancel, visible, status } = props;
  if (!visible) {
    return null;
  }
  return (
    <Layer>
      <Box pad='medium'>
        <Box direction='row' align='center' justify='start'>
          <Box pad='small'>{getIcon(status || "unknown")}</Box>
          <Heading level='3' margin={{ vertical: "small" }}>
            {title || "Confirmation"}
          </Heading>
        </Box>
        <Box pad='medium'>
          <Text>{message}</Text>
        </Box>
        <Box align='center' flex>
          {processing ? (
            <Spinning />
          ) : (
            <Box direction='row' flex pad='small' fill>
              <Box align='start' flex>
                <Button label='No' onClick={onCancel} />
              </Box>
              <Box align='end'>
                <Button
                  primary
                  label='Yes'
                  onClick={async () => {
                    setProcessing(true);
                    await onConfirm();
                    setProcessing(false);
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Layer>
  );
};

/*
const checkMinimumEvents = (app_bundle, events, isAdn) => {
  // console.info({ app_bundle, events })
  let minimumEvents = {
    revenue: null,
    // cost: null,
    // ftd: null,
    // registration: null,
  };

  if (!isAdn) {
    minimumEvents = {
      revenue: null,
      cost: null,
      // ftd: null,
      // registration: null,
    };
  }

  const appBundleEvents = events.filter(
    (event) =>
      event.app_bundle === app_bundle &&
      event.isAdn === app_bundle.isAdn &&
      !event.event_geos
  );

  appBundleEvents.forEach((event) => {
    if (minimumEvents[event.event_type] !== undefined) {
      minimumEvents[event.event_type] = event;
    }
  });

  const hasMinimumEvents =
    Object.values(minimumEvents).filter((e) => e).length >= 1;

  return { minimumEvents, hasMinimumEvents };
};
*/
function MinimumEventsLayer(props) {
  const navigate = useNavigate();

  const { minimumEventsData, onCancel } = props;

  if (!minimumEventsData) {
    return null;
  }

  const { minimumEvents, isAdn, app_bundle, app_name } = minimumEventsData;
  // console.info(minimumEvents)
  return (
    <Layer>
      <Box pad='medium'>
        <Box direction='row' justify='start' align='center' gap='small'>
          <Alert />
          <Heading level='3' margin={"none"}>
            {"App Bundle Global Events Missing"}
          </Heading>
        </Box>
        <Box pad={{ vertical: "small" }}>
          <Text>
            {`App Bundle requires a minimum of ${isAdn ? 1 : 2
              } global events to be enabled.`}
          </Text>
          <Box pad='small' gap='small'>
            {isAdn ? (
              <Box direction='row' align='center' gap='small' key={"revenue"}>
                <CheckBox disabled checked={!!minimumEvents["revenue"]} />
                <Text>Revenue</Text>
              </Box>
            ) : (
              Object.keys(minimumEvents).map((eventName) => {
                return (
                  <Box
                    direction='row'
                    align='center'
                    gap='small'
                    key={eventName}
                  >
                    <CheckBox disabled checked={!!minimumEvents[eventName]} />
                    <Text>{_.capitalize(eventName)}</Text>
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
        <Box direction='row' flex pad='small' fill>
          <Box align='start' flex>
            <Button label='Cancel' onClick={() => onCancel()} />
          </Box>
          <Box align='end'>
            <Button
              primary
              label='Go To Events Page'
              onClick={async (e) => {
                //   console.info("PUSH");
                e.preventDefault();
                navigate({
                  pathname: `/af-combined/events`,
                  state: {
                    app_bundle: app_bundle,
                    isAdn: isAdn,
                    minimumEvents,
                    app_name: app_name,
                  },
                });

                // onCancel()
              }}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
}

function Config(props) {
  const {
    fetchCombinedData,
    appsflyer,
    adnNetworks,
    agencies,
    duplicateBundleConfig,
  } = props;

  //  console.info("CONFIG<PROPS", props)
  const { loading, config, events } = appsflyer;

  // const config = rawConfig
  // mobile ?
  const [search, setSearch] = React.useState("");
  const [configType, setConfigType] = React.useState(CONFIG_TYPE_OPTS[0].value);
  const [filteredData, setFilteredData] = React.useState(config);
  const [sortSettings, setSortSettings] = React.useState({
    property: "modified",
    direction: "desc",
  });
  const [hideDisabled, setHideDisabled] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState({});
  const [minimumEventsLayerData, setMinimumEventLayerData] =
    React.useState(false);
  const [appBundleLayerData, setAppBundleLayerData] = React.useState(false);
  const [networkOptions, setNetworkOptions] = React.useState(["All"]);
  const [networkValue, setNetworkValue] = React.useState("All");
  const [adnBundleToDupe, setDuplicateAdnBundle] = React.useState(
    /*['id465712788', 0]*/ null
  );
  const {
    updateAppBundleConfig,
    // resetAppBundleData,
    createAppBundle,
    // deleteAppBundle,
  } = props;

  const addAppBundleProps = {
    adnNetworks,
    agencies,
    appBundleLayerData,
    setAppBundleLayerData,
    createAppBundle,
  };

  const onEditAppBundle = (event, { app_bundle, app_name, isAdn, adn_id, agency_id }) => {
    setAppBundleLayerData({
      app_bundle,
      app_name,
      isEdit: true,
      isAdn: isAdn,
      adn_id,
      agency_id,
    });
    // onClick={() => setAppBundleLayerData(true)}
  };

  const onToggleEnabled = (
    event,
    { app_bundle, first_setup, isAdn, adn_id, app_name, agency_id },
    props
  ) => {
    const title = "Update Confirmation";
    const isEnabled = !event.target.checked;

    const word = isEnabled ? "disable" : "enable";
    const message = `Do you want to ${word} ${isAdn ? "'AdNetwork'" : "'Agency'"
      } App Bundle '${app_bundle}' ?`;
    const adnParams = isAdn ? { adn_id } : {};
    const agencyParams = !isAdn ? { agency_id } : {};
    const update = {
      enabled: !event.target.checked,
      app_bundle,
      first_setup,
      ...adnParams,
      ...agencyParams
    };

    console.info({ update })

    setConfirmation({
      title,
      message,
      visible: true,
      onConfirm: async () => {
        // setConfirmation({ processing: true, ...confirmation })
        await updateAppBundleConfig(update, isAdn);
        setConfirmation({});
      },
      onCancel: () => setConfirmation({}),
    });
  };

  React.useEffect(() => {
    async function getData() {
      await fetchCombinedData({});
    }

    if (config.length < 1) {
      getData();
    }

    let filteredData = config
      .filter((c) => {
        const searchFilter = search
          ? c.app_bundle.toLowerCase().indexOf(search) > -1 ||
          c.app_name.toLowerCase().indexOf(search) > -1
          : true;

        const typeFilter =
          configType === 2 ? true : Boolean(c.isAdn) === Boolean(configType);

        const disabledFilter = hideDisabled ? !!c.enabled === true : true;

        const networkFilter = networkValue === "All"
           || networkValue.includes(c.adn_name || c.agency_name)
        //   (
        //     // c.isAdn &&
        //     (c.adn_name === networkValue
        //       || c.agency_name === networkValue
        //       || networkValue === "All"))
        // // ||!c.isAdn;
        return searchFilter && typeFilter && disabledFilter && networkFilter;
      })
      .map((rc) => {
        const bundleEvents = (events || []).filter(
          (e) =>
            e.app_bundle === rc.app_bundle &&
            e.isAdn === rc.isAdn &&
            e.adn_id === rc.adn_id && 
            e.agency_id === rc.agency_id

        );
        const bundleEventsNum = bundleEvents.length;
        return {
          ...rc,
          bundleEvents,
          bundleEventsNum,
        };
      });

    setFilteredData(filteredData);
    setNetworkOptions(_.uniq(config.map((o) => o.adn_name || o.agency_name || "All")));
    // console.log(config);
  }, [
    fetchCombinedData,
    config,
    search,
    configType,
    hideDisabled,
    events,
    networkValue,
  ]);

  console.info({networkValue})
  // console.info(configType)
  return (
    <Box
      flex
      fill
      width='100%'
      margin='none'
      overflowY='auto'
      alignSelf='center'
    >
      <MinimumEventsLayer
        minimumEventsData={minimumEventsLayerData}
        onCancel={() => {
          setMinimumEventLayerData(false);
        }}
        // navigateToEvents={() => navigate()}
        {...props}
      />
      <AddBundleLayer {...addAppBundleProps} />
      <ConfirmLayer {...confirmation} />
      <DupeBundleConfigLayer
        {...{
          adnBundleToDupe,
          onCancel: () => setDuplicateAdnBundle(null),
          events,
          config,
          adnNetworks,
          agencies,
          duplicateBundleConfig,
        }}
      />
      {/* DATA TABLE  */}
      <Loader loading={loading} text='Loading Offers data...'>
        <Box pad='small' gap='small'>
          <TextInput
            value={search}
            onChange={({ target }) => setSearch(target.value.toLowerCase())}
            icon={<Search />}
            placeholder='search ...'
          />
          <Box direction='row' gap='small' justifyContent='center'>
            <Box width='small'>
              <Select
                id='select'
                name='select'
                placeholder='Select'
                labelKey='label'
                valueKey={{ key: "value", reduce: true }}
                value={configType}
                onChange={({ value: nextValue }) => setConfigType(nextValue)}
                options={CONFIG_TYPE_OPTS}
              />
            </Box>
            {!!configType ? (
              <Box width='small'>
                <Select
                  id='select'
                  name='select'
                  placeholder='Select Networks/Agencies'
                  multiple
                  closeOnChange={false}
                  messages={{
                    multiple: networkValue !== "All" && networkValue.join(", ")
                  }}
                  // labelKey="label"
                  // valueKey={{ key: "value", reduce: true }}
                  value={networkValue}
                  onChange={({ value: nextValue }) =>
                      // console.info({nextValue})
                    setNetworkValue(nextValue.length === 0 ? "All" : nextValue)
                  }
                  options={networkOptions}
                />
              </Box>
            ) : null}
            <Box width='small' justify='center' justifyContent='center'>
              <CheckBox
                style={{ borderRadius: "5px" }}
                toggle
                checked={hideDisabled}
                label='Hide disabled'
                onClick={() => setHideDisabled((d) => !d)}
              />
            </Box>
            <Box width='small'>
              <Button
                style={{ borderRadius: "5px" }}
                primary
                label='Add Bundle'
                icon={<Add />}
                onClick={() => setAppBundleLayerData({ isEdit: false })}
              />
            </Box>
          </Box>
        </Box>
        {/* <SpeedDial actions={speedDialActions} /> */}
        {/* <FiltersV2
                    fields={[
                        
                            {
                                name: "fromDate",
                                type: "search",
                                // width: "40%",
                                placeholder: `Search for app bundle or name`,
                            },
                            {
                                name: "addBundle",
                                type: "button", 
                                label: "Add Bundle",
                            },
                    ]}
                    handleSubmit={setQueryCriteria}
                    firstLoad={firstLoad}
                    onFirstLoad={() => setFirstLoad(false)}
                /> */}
        <Box
          style={{
            flex: 13,
            overflowX: "auto",
            paddingBottom: "5vh",
            // overflowY: "hidden",
          }}
        >
          <DataTable
            pin
            // fill
            // key={groupBy + reportsData.length}
            // rowProps={getRowProps(reportsData)}
            background={{
              header: "dark-3",
              // body: ["#dff0d8"],
              footer: "light-3",
            }}
            sortable
            onSort={({ direction, property }) =>
              setSortSettings({
                property,
                direction,
              })
            }
            sort={sortSettings}
            primaryKey={"key"}
            data={filteredData}
            columns={getTableColumns({
              onEditAppBundle,
              onToggleEnabled,
              props: appsflyer,
              events,
              config,
              adnNetworks,
              agencies,
              setDuplicateAdnBundle,
            })}
            wideColumns={[2]}
          />
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  const adnNetworks = state.appsflyer_adn.constants;
  const agencies = state.appsflyer.constants;


  const { appsflyer_combined: appsflyer } = state;

  return { appsflyer, browser, adnNetworks, agencies };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      AppsFlyerActions,
      AppBarActions,
      AppsFlyerCombinedActions
    ),
    dispatch
  );
}

const getTableColumns = ({
  onEditAppBundle,
  onToggleEnabled,
  props,
  events,
  config,
  agencies,
  adnNetworks,
  setDuplicateAdnBundle,
}) => [
    {
      property: "isAdn",
      header: "Type",
      render: ({ isAdn, adn_id, agency_id }) => {
        return (
          <AppsflyerEntityLabel
            {...{ isAdn, adn_id, agency_id, adnNetworks, agencies }}
          />
        );
      },
    },
    {
      property: "enabled",
      header: "Enabled",
      render: ({ enabled, app_bundle, first_setup, isAdn, adn_id, app_name, agency_id }) => {
        return (
          <Cell flex>
            <CheckBox
              style={{
                height: "2px",
              }}
              size='small'
              plain
              toggle
              checked={Boolean(enabled)}
              onClick={(e) => {
                onToggleEnabled(
                  e,
                  {
                    app_bundle,
                    first_setup,
                    isAdn,
                    app_name,
                    adn_id,
                    agency_id
                  },
                  props
                );
              }}
            />
          </Cell>
        );
      },
    },
    {
      property: "bundleEventsNum",
      header: "Has Events",
      render: ({
        enabled,
        app_bundle,
        first_setup,
        bundleEvents,
        bundleEventsNum,
        isAdn,
        app_name,
        adn_id,
        in_app_events_num,
      }) => {
        return (
          <Cell flex>
            {/* <CheckBox
            style={{
              height: "2px",
            }}
            size="small"
            plain
            disabled
            // toggle
            checked={Boolean(in_app_events_num)}
          // onClick={(e) => {
          //   onToggleEnabled(
          //     e,
          //     {
          //       app_bundle,
          //       first_setup,
          //       isAdn,
          //       app_name,
          //     },
          //     props
          //   );
          // }}
          /> */}
            <SetupEventsComponents
              {...{ bundleEvents, bundleEventsNum, app_bundle, isAdn, adn_id }}
            />
            {/* {bundleEventsNum} */}
          </Cell>
        );
      },
    },
    {
      property: "first_setup",
      header: <Text>InApp Events</Text>,
      render: ({
        app_bundle,
        in_app_events_num,
        in_app_events_json,
        bundleEvents,
      }) => {
        return (
          <InAppEventsComponent
            {...{
              app_bundle,
              in_app_events_json,
              in_app_events_num,
              bundleEvents,
            }}
          />
        );
      },
    },
    {
      property: "app_bundle",
      header: "App Bundle",
      render: defaultRenderer("app_bundle"),
    },
    {
      property: "app_name",
      header: "App Name",
      render: defaultRenderer("app_name"),
    },
    {
      property: "modified",
      header: "Modified At",
      render: defaultRenderer("modified"),
    },
    {
      property: "created",
      header: "Created At",
      render: defaultRenderer("created"),
    },
    {
      property: "last_to",
      header: "Updated Till",
      render: defaultRenderer("last_to"),
    },
    {
      property: "actions",
      header: "",
      render: ({ app_bundle, app_name, isAdn, adn_id, agency_id }) => (
        <Box direction='row'>
          {/* <Button
                                    icon={<Trash />}
                                    onClick={(e) => onDeleteBundle(e, app_bundle)}
                                /> */}
          <Button
            size='small'
            icon={<Edit size='small' />}
            //   label={`${isAdn}`}
            onClick={(e) => {
              onEditAppBundle(e, { app_bundle, app_name, isAdn, adn_id, agency_id })
            }
            }
          />
          <Button
            size='small'
            icon={<Duplicate size='small' />}
            disabled={
              !isAdn &&
              config.filter((c) => c.app_bundle === app_bundle && !c.isAdn)
                .length === agencies.OPTS.length
            }
            onClick={(e) =>
              setDuplicateAdnBundle([
                app_bundle,
                isAdn ? adn_id : agency_id,
                isAdn,
              ])
            }
          />
          {/* <Button
              icon={<History color="status-critical" />}
              onClick={(e) =>
                onResetAppBundleHistory(e, { app_bundle, app_name })
              }
            /> */}
        </Box>
      ),
    },
  ];


export default connect(mapStateToProps, mapDispatchToProps)(Config);
